<template>
  <v-row justify="start">
    <v-dialog v-model="dialogCreateFaq" scrollable persistent max-width="700px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="mx-7">
          <b class="color_default">Tambah Faq</b>
        </div>
        <v-card-text>
          <v-container>
            <div class="mt-2">
              <p class="color_txt">Pertanyaan:</p>
              <input type="text" v-model="question" class="title_input" />
            </div>
            <div class="mt-5">
              <p class="color_txt">Jawaban:</p>
              <vue-editor
                :editorToolbar="customToolbar"
                v-model="answer"
                class="mb-3 rounded-max"
              ></vue-editor>
              <div>
                <v-overlay
                  :absolute="true"
                  color="white"
                  opacity="1"
                  :value="loading"
                >
                  <v-progress-circular
                    indeterminate
                    class="mr-2"
                    color="#00b4cc"
                  ></v-progress-circular>
                  <b class="color_default">Loading...</b>
                </v-overlay>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            dark
            color="#59B5CC"
            class="pa-5 text-capitalize"
            depressed
            @click="createData"
            >Tambah</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
// import Swal from "sweetalert2";

export default {
  name: "createFaq",
  props: ["dialogCreateFaq"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
      customToolbar: [],
      question: "",
      answer: "",
    };
  },
  methods: {
    createData() {
      this.loading = true;
      let body = {
        tentang_id: 5,
        pertanyaan: this.question,
        jawaban: this.answer,
      };
      this.$store
        .dispatch("tentang/createFaq", body)
        .then(() => {
          this.loading = false;
          this.$emit("close");
          this.$emit("refetch");
          this.question = ''
          this.answer = ''
          Swal.fire({
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            position: "top-end",
            timer: 3000,
            toast: true,
            customClass: {
              title: "family",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.ql-toolbar {
  border-radius: 8px 8px 0 0 !important;
}
.ql-container {
  border-radius: 0 0 8px 8px !important;
}
.title_input {
  border: 1px #e5edee solid;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
.title_input:focus {
  outline: none;
}
</style>