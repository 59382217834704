<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="350px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="height: 230px" class="pa-5">
          <b>Pilih Gambar</b>
          <div style="margin-top: 20px">
            <v-file-input v-model="file" dense></v-file-input>
            <!-- <v-text-field
              v-model="link"
              hide-details
            ></v-text-field> -->
            <v-divider></v-divider>
            <i class="red--text"
              ><p style="font-size: 10px">
                Gambar disarankan memiliki rasio 16 : 9 dengan format .jpg,
                .jpeg, .png
              </p></i
            >
          </div>
          <div
            class="d-flex justify-center"
            style="margin-top: 20px"
            @click="updateData"
          >
            <v-btn rounded dark color="#59B5CC" class="pa-5" depressed
              >Simpan</v-btn
            >
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            ></v-progress-circular>
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
// import Swal from "sweetalert2";

export default {
  name: "updateDiagram",
  props: ["dialogAlert", "data"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
      file: null,
      link: "",
    };
  },
  methods: {
    updateData() {
      let body = new FormData();
      body.append("file", this.file);
      body.append("id", this.data.id);
      let data = {
        id: this.data.id,
        body: body,
      };
      this.loading = true;
      this.$store
        .dispatch("tentang/updateDiagram", data)
        .then(() => {
          this.$emit("refetch");
          this.$emit("close");
          this.loading = false;
          this.Swal("success", "Success");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", "Gagal!");
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>