<template>
  <div class="container_about">
    <div v-if="content" class="pa-5">
      <div class="d-flex pa-2 mb-3 align-center">
        <blue-circle class="mr-3" />
        <h3 class="color_default">Konfigurasi Tentang</h3>
      </div>
      <v-card class="rounded-max custom_card pa-5" style="margin-top: 20px">
        <input
          type="text"
          :disabled="!status.item1"
          v-model="content[0].judul"
          class="title_input"
        />
        <div class="mt-5">
          <vue-editor
            :editorToolbar="customToolbar"
            :disabled="!status.item1"
            v-model="content[0].konten"
            class="mb-3 rounded-max"
          ></vue-editor>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            rounded
            dark
            color="#59B5CC"
            class="pa-5"
            v-if="!status.item1"
            @click="status.item1 = true"
            depressed
            >Edit</v-btn
          >
          <v-btn
            rounded
            dark
            color="#65CCBF"
            class="pa-5"
            v-if="status.item1"
            depressed
            @click="updateData(1, 0, 'item1')"
            >Simpan</v-btn
          >
        </div>
      </v-card>

      <v-card class="rounded-max custom_card pa-5" style="margin-top: 20px">
        <input
          type="text"
          :disabled="!status.item2"
          v-model="content[1].judul"
          class="title_input"
        />
        <div class="mt-5">
          <vue-editor
            :editorToolbar="customToolbar"
            v-model="content[1].konten"
            :disabled="!status.item2"
            class="mb-3 rounded-max"
          ></vue-editor>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            rounded
            dark
            color="#59B5CC"
            class="pa-5"
            v-if="!status.item2"
            @click="status.item2 = true"
            depressed
            >Edit</v-btn
          >
          <v-btn
            rounded
            dark
            color="#65CCBF"
            class="pa-5"
            v-if="status.item2"
            depressed
            @click="updateData(2, 1, 'item2')"
            >Simpan</v-btn
          >
        </div>
      </v-card>

      <v-card class="rounded-max custom_card pa-5" style="margin-top: 20px">
        <input
          type="text"
          :disabled="!status.item3"
          v-model="content[2].judul"
          class="title_input"
        />
        <div class="mt-5">
          <vue-editor
            :disabled="!status.item3"
            :editorToolbar="customToolbar"
            v-model="content[2].konten"
            class="mb-3 rounded-max"
          ></vue-editor>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            rounded
            dark
            color="#59B5CC"
            class="pa-5"
            v-if="!status.item3"
            @click="status.item3 = true"
            depressed
            >Edit</v-btn
          >
          <v-btn
            rounded
            dark
            color="#65CCBF"
            class="pa-5"
            v-if="status.item3"
            depressed
            @click="updateData(3, 2, 'item3')"
            >Simpan</v-btn
          >
        </div>
      </v-card>
      <v-card class="rounded-max custom_card pa-5" style="margin-top: 20px">
        <h2 class="color_txt">Diagram Pengguna (User)</h2>
        <v-col class="d-flex" cols="12" sm="3">
          <v-select
            :items="content[3].diagram"
            class="ma-0 pa-0"
            return-object
            v-model="diagram"
            item-text="jenis"
            dense
            outlined
          ></v-select>
        </v-col>
        <div class="d-flex justify-center">
          <img :src="diagram.url_file" height="350px" alt="" />
        </div>
        <div class="d-flex justify-end">
          <v-btn
            rounded
            dark
            color="#59B5CC"
            class="pa-5"
            @click="dialogAlert = true"
            depressed
            >Edit</v-btn
          >
        </div>
      </v-card>

      <v-card class="rounded-max custom_card pa-5" style="margin-top: 20px">
        <h2 class="color_txt mb-3">FAQ</h2>
        <v-card
          outlined
          class="rounded-lg pa-3 mb-3"
          v-for="(faq, idx) in content[4].faq"
          :key="`faq-${idx}`"
        >
          <div>
            <div class="d-flex mb-3">
              <v-icon class="mr-3" color="#61C4D7"
                >mdi-plus-circle-outline</v-icon
              >
              <p class="ma-0 pa-0 color_txt">{{ faq.pertanyaan }}</p>
            </div>
            <div class="bg_main pa-2 rounded">
              <p v-html="faq.jawaban"></p>
            </div>
          </div>
          <div class="d-flex justify-end mt-3">
            <v-btn
              rounded
              dark
              color="#59B5CC"
              @click="updateFaq(faq)"
              class="pa-5 mr-3"
              depressed
              ><v-icon>mdi-square-edit-outline</v-icon></v-btn
            >
            <v-btn
              rounded
              dark
              outlined
              color="#59B5CC"
              @click="delFaq(faq)"
              class="pa-5"
              depressed
              ><v-icon>mdi-trash-can-outline</v-icon></v-btn
            >
          </div>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            dark
            outlined
            color="#59B5CC"
            @click="dialogCreateFaq = true"
            class="pa-5 text-capitalize"
            depressed
            ><v-icon>mdi-plus</v-icon> Tambah Pertanyaan</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

      
    </div>
    <div class="pa-3">
      <update-diagram
        v-bind:dialogAlert="dialogAlert"
        @close="dialogAlert = false"
        @refetch="getData"
        v-bind:data="diagram"
      />
      <delete-faq
        v-bind:dialogDelFaq="dialogDelFaq"
        @close="dialogDelFaq = false"
        v-bind:body="faqItem"
        @refetch="getData"
      />
     
      <create-faq
        v-bind:dialogCreateFaq="dialogCreateFaq"
        @close="dialogCreateFaq = false"
        @refetch="getData"
      />
      <update-faq
        v-bind:dialogUpdateFaq="dialogUpdateFaq"
        v-bind:body="faqItem"
        @close="dialogUpdateFaq = false"
        @refetch="getData"
      />
    </div>
    <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#00b4cc"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import BlueCircle from "../../../components/General/BlueCircle";
import CreateFaq from "./createFaq.vue";
import DeleteFaq from "./deleteFaq.vue";

import UpdateDiagram from "./updateDiagram.vue";
import UpdateFaq from "./updateFaq.vue";
export default {
  components: { BlueCircle, UpdateDiagram, DeleteFaq, CreateFaq, UpdateFaq, },
  name: "TentangConf",
  computed: {
    ...mapState({
      content: (state) => state.tentang.tentang,
    }),
  },
  data() {
    return {
      loading: false,
      dialogAlert: false,
      diagram: null,
      customToolbar: [],
      dialogDelFaq: false,
      dialogCreateFaq: false,
      dialogUpdateFaq: false,
      faqItem: null,
      panduanItem: null,
      status: {
        item1: false,
        item2: false,
        item3: false,
      },
      mutateSeries: {
        status: true,
        code: "00",
        message: "Success",
        endpoint: "api/grafik/limbah-fasyankes/pemilihan",
        data: {
          total: 6,
          items: [
            {
              name: "Dilakukan pemilahan",
              value: 3,
            },
            {
              name: "Dilakukan pemilahan",
              value: 3,
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    delFaq(item) {
      this.faqItem = item;
      this.dialogDelFaq = true;
    },
    updateFaq(item) {
      this.faqItem = item;
      this.dialogUpdateFaq = true;
    },
    updateData(id, idx, status) {
      let body = {
        judul: this.content[idx].judul,
        konten: this.content[idx].konten,
      };
      let data = {
        id: id,
        body: body,
      };
      this.$store.dispatch("tentang/updateTentang", data).then(() => {
        this.status[status] = false;
        this.Swal("success", "Success");
      });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
    getData() {
      this.loading = true;
      this.$store.dispatch("tentang/getTentang").then((data) => {
        let diagram = null;
        data.forEach((item) => {
          if (item.diagram.length) {
            diagram = item.diagram[0];
            this.diagram = diagram;
          }
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.app_name {
  font-size: 60px;
}
.container_about {
  padding: 20px;
}
.ql-toolbar {
  border-radius: 8px 8px 0 0 !important;
}
.ql-container {
  border-radius: 0 0 8px 8px !important;
}
.title_input {
  border: 2px #e5edee solid;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
.title_input:focus {
  outline: none;
}
</style>